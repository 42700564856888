

    import { computed, defineComponent, PropType } from 'vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    // import BlockRevealAnimation from './BlockRevealAnimation.vue'
    import Container from './Container.vue'
    import Heading from './Heading.vue'
    import Paragraph from './Paragraph.vue'
    import LoopingVideo from './LoopingVideo.vue'
    import Picture from './Picture.vue'
    import TitleAndList from './TitleAndList.vue'
    import ThemeChanger from './ThemeChanger.vue'
    import Checklist from './Checklist.vue'
    import Testimonial from './Testimonial.vue'
    import Slider from './Slider.vue'
    import { isVideoType } from '../utils'
    import { Theme } from '../enums/Theme'
    import { useCarouselSizes } from '../compositions/UseCarouselSizes'

    export default defineComponent({
        name: 'BodyContent',
        components: {
            // BlockRevealAnimation,
            Container,
            Column,
            Grid,
            Slider,
            Heading,
            Paragraph,
            Picture,
            LoopingVideo,
            Checklist,
            TitleAndList,
            ThemeChanger,
            Testimonial
        },
        props: {
            data: { type: Object, required: true },
            usesCustomThemes: { type: Boolean, default: false },
            singleSpace: { type: Boolean, default: false },
            isLast: { type: Boolean, default: false },
            defaultBackground: { type: String, default: null },
            defaultGradient: { type: String, default: null },
            fallbackTheme: { type: String as PropType<Theme>, default: Theme.Default }
        },
        setup(props) {

            return {
                Theme,
                isVideoType,
                padding: computed(() => {

                    // 'py-32 md:py-48' : 'py-16 md:py-24'
                    if (props.isLast) {

                        return props.usesCustomThemes ? 'py-32 md:py-48' : 'py-16 md:py-24'

                    }

                    if (props.singleSpace) {

                        return 'pt-32 md:pt-48'

                    }

                    return props.usesCustomThemes ? 'pt-32 md:pt-48' : 'pt-16 md:pt-24'

                }),
                sectionTheme: computed(() => {

                    const themes: Record<string, string> = {
                        light: Theme.Default,
                        dark: Theme.Dark
                    }

                    return props.usesCustomThemes ? (themes[props.data.theme.value] ?? props.fallbackTheme) : props.fallbackTheme

                }),
                isMedia: computed(() => props.data.type === 'media'),
                hasMedia: computed(() => props.data.assets && props.data.assets.length > 0),
                hasExtensions: computed(() => props.data.extensions && props.data.extensions.length > 0),
                sizes: computed(() => useCarouselSizes(props.data.assets))
            }

        }
    })

