

    import { defineComponent, ref, onMounted, onUnmounted, computed } from 'vue'
    import BodyContent from '../components/BodyContent.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import Container from '../components/Container.vue'
    import CustomCursor from '../components/CustomCursor.vue'
    import Heading from '../components/Heading.vue'
    import ContactHeader from '../components/ContactHeader.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Picture from '../components/Picture.vue'
    import Projects from '../components/Projects.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { useCms } from '../compositions/UseCms'
    import { useTheme } from '../compositions/UseTheme'
    import { scrollTo } from '../compositions/HeaderComposition'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Theme } from '../enums/Theme'
    import { Route } from '../enums/Route'
    import { useRoute } from 'vue-router'
    import { themeColor } from '../themeColor'

    export default defineComponent({
        name: 'CaseStudy',
        components: {
            Container,
            BodyContent,
            Column,
            CustomCursor,
            Grid,
            Heading,
            Paragraph,
            Picture,
            Projects,
            ContactHeader,
            ThemeChanger,
            MetaInfo
        },
        // Remove it, not sure if it's necessary
        beforeRouteEnter(to, from, next) {

            useCms().caseStudyData(to.params.slug as string) ? next() : next({ name: Route.NotFound })

        },
        setup() {

            const { setTheme, setBackground } = useTheme()

            onMounted(() => {

                calcCoverHeight()

                window.addEventListener('resize', calcCoverHeight)

            })

            onUnmounted(() => {

                window.removeEventListener('resize', calcCoverHeight)

            })

            const { caseStudyData } = useCms()
            const route = useRoute()
            const caseStudy = caseStudyData(route.params.slug)
            const caseStudyElement = ref<HTMLDivElement | null>(null)
            const { cursor } = useTranslation()
            const coverHeight = ref<string>('1000px')

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            setTheme(caseStudy?.theme?.value ?? Theme.Dark)
            setBackground(caseStudy?.default_background_color ?? themeColor[caseStudy?.theme?.value ?? Theme.Dark])

            const scrollToContent = async() => {

                if (caseStudyElement.value) {

                    const boundingBox = caseStudyElement.value.getBoundingClientRect()

                    await scrollTo(
                        document.documentElement,
                        window.scrollY,
                        window.scrollY + boundingBox.top + window.innerHeight,
                        500
                    )

                }

            }

            function calcCoverHeight() {

                const originalWidth = caseStudy?.cover?.desktop_asset?.width
                const originalHeight = caseStudy?.cover?.desktop_asset?.height
                const ratio = window.innerWidth / originalWidth

                if (originalHeight && originalWidth && ratio) {

                    coverHeight.value = `${ Math.floor(originalHeight * ratio) }px`

                } else {

                    coverHeight.value = '100vh'

                }

            }

            return {
                Theme,
                caseStudy,
                caseStudyElement,
                scrollToContent,
                cursor,
                coverHeight,
                isLightTheme: computed(() => {

                    return caseStudy?.theme?.value === 'light' as Theme

                })
            }

        }
    })
