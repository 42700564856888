import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-6 md:py-14 md:px-16" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_AOS, {
        type: "fade-up",
        duration: "800",
        delay: "1000"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, { span: [ 2, 1 ] }, {
            default: _withCtx(() => [
              _createVNode(_component_Paragraph, {
                innerHTML: _ctx.data.label,
                heading: ""
              }, null, 8, ["innerHTML"])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            span: [ 2, 5 ],
            class: "testimonial__quote sm:mt-8"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Paragraph, {
                  innerHTML: _ctx.data.text
                }, null, 8, ["innerHTML"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            span: [ 2, 5 ],
            push: [ 0, 1 ],
            class: "mt-9"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Paragraph, {
                innerHTML: _ctx.data.author,
                "no-margin": ""
              }, null, 8, ["innerHTML"]),
              _createVNode(_component_Paragraph, {
                innerHTML: _ctx.data.position
              }, null, 8, ["innerHTML"]),
              _createElementVNode("img", {
                class: "mt-5",
                src: _ctx.data?.logo?.permalink,
                alt: "",
                width: "80"
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}