import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-5 w-5 sm:mt-2 mr-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkmark = _resolveComponent("Checkmark")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createBlock(_component_Column, {
          key: index,
          span: [ 2, 4 ],
          push: [ 0, 1 ]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AOS, {
              type: "fade-up",
              duration: "600",
              delay: 900
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["checklist__item py-6 px-6 flex md:items-center", { '--last': index === _ctx.data.length-1 }])
                }, [
                  (_ctx.withIcon)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_Checkmark, {
                          hollow: "",
                          class: "h-5 w-5"
                        })
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Paragraph, { innerHTML: item }, null, 8, ["innerHTML"])
                ], 2)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}