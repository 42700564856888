
    import { defineComponent, PropType } from 'vue'
    import Checkmark from './Icons/Checkmark.vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Paragraph from './Paragraph.vue'

    export default defineComponent({
        name: 'Checklist',
        props: {
            data: { type: Array as PropType<string[]>, required: true },
            withIcon: { type: Boolean, default: false }
        },
        components: {
            Checkmark,
            Column,
            Grid,
            Paragraph
        }
    })
