
    import { defineComponent } from 'vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Paragraph from './Paragraph.vue'

    export default defineComponent({
        name: 'Testimonial',
        props: {
            data: { type: Object as any, required: true }
        },
        components: {
            Column,
            Grid,
            Paragraph
        }
    })
