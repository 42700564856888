import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 1,
  class: "w-screen"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_LoopingVideo = _resolveComponent("LoopingVideo")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_TitleAndList = _resolveComponent("TitleAndList")!
  const _component_Checklist = _resolveComponent("Checklist")!
  const _component_Testimonial = _resolveComponent("Testimonial")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!

  return (_openBlock(), _createBlock(_component_ThemeChanger, {
    to: _ctx.sectionTheme,
    background: _ctx.defaultBackground ?? _ctx.data?.background_color
  }, {
    default: _withCtx(() => [
      (_ctx.isMedia && _ctx.hasMedia)
        ? (_openBlock(), _createBlock(_component_Container, {
            key: 0,
            fluid: "",
            class: "mt-32 md:mt-48"
          }, {
            default: _withCtx(() => [
              (_ctx.data?.carousel)
                ? (_openBlock(), _createBlock(_component_Grid, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, { span: [ 2, 6 ] }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Slider, {
                            images: _ctx.data.assets,
                            sizes: _ctx.sizes,
                            "zoom-on-hover": _ctx.data?.zoom_on_hover,
                            "default-gradient": _ctx.defaultGradient
                          }, null, 8, ["images", "sizes", "zoom-on-hover", "default-gradient"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_AOS, {
                      type: "fade-up",
                      easing: "ease-in",
                      duration: "300",
                      offset: "-1000"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.assets, (media, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (_ctx.data.style.value === 'grid')
                              ? (_openBlock(), _createBlock(_component_Grid, { key: 0 }, {
                                  default: _withCtx(() => [
                                    (_ctx.isVideoType(media.desktop_asset.mime_type))
                                      ? (_openBlock(), _createBlock(_component_LoopingVideo, {
                                          key: 0,
                                          "default-gradient": _ctx.defaultGradient,
                                          source: media.desktop_asset.permalink,
                                          class: "border-none",
                                          cover: media.video_cover
                                        }, null, 8, ["default-gradient", "source", "cover"]))
                                      : (_openBlock(), _createBlock(_component_Picture, {
                                          key: 1,
                                          class: "border-none",
                                          auto: "",
                                          cover: "",
                                          image: media,
                                          "default-gradient": _ctx.defaultGradient
                                        }, null, 8, ["image", "default-gradient"]))
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  (_ctx.isVideoType(media.desktop_asset.mime_type))
                                    ? (_openBlock(), _createBlock(_component_LoopingVideo, {
                                        key: 0,
                                        source: media.desktop_asset.permalink,
                                        cover: media.video_cover,
                                        "default-gradient": _ctx.defaultGradient,
                                        class: "border-none",
                                        "full-width": ""
                                      }, null, 8, ["source", "cover", "default-gradient"]))
                                    : (_openBlock(), _createBlock(_component_Picture, {
                                        key: 1,
                                        class: "border-none",
                                        image: media,
                                        width: "100vw",
                                        maxWidth: "100vw",
                                        height: "auto",
                                        maxHeight: "100%",
                                        "default-gradient": _ctx.defaultGradient
                                      }, null, 8, ["image", "default-gradient"]))
                                ]))
                          ], 64))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]))
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_Container, {
            key: 1,
            fluid: "",
            class: _normalizeClass(_ctx.padding)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Grid, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    span: [ 2, 4 ],
                    push: [ 0, 1 ]
                  }, {
                    default: _withCtx(() => [
                      (_ctx.data.heading)
                        ? (_openBlock(), _createBlock(_component_Heading, {
                            key: 0,
                            tag: "h2",
                            innerHTML: _ctx.data.heading
                          }, null, 8, ["innerHTML"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Paragraph, {
                        innerHTML: _ctx.data.content
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.hasExtensions)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.extensions, (extension, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (extension.type === 'list')
                          ? (_openBlock(), _createBlock(_component_TitleAndList, {
                              title: extension.label,
                              list: extension.items,
                              key: `${extension.label}.${index}`,
                              class: "pt-16 md:pt-24"
                            }, null, 8, ["title", "list"]))
                          : _createCommentVNode("", true),
                        (extension.type === 'checklist')
                          ? (_openBlock(), _createBlock(_component_Checklist, {
                              key: 1,
                              data: extension.checklist,
                              "with-icon": extension.include_check_icon,
                              class: "pt-16 md:pt-24"
                            }, null, 8, ["data", "with-icon"]))
                          : _createCommentVNode("", true),
                        (extension.type === 'testimonial')
                          ? (_openBlock(), _createBlock(_component_Testimonial, {
                              key: 2,
                              data: extension,
                              class: "pt-16 md:pt-24"
                            }, null, 8, ["data"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class"]))
    ]),
    _: 1
  }, 8, ["to", "background"]))
}