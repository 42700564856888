import anime from 'animejs'
import { onBeforeRouteLeave } from 'vue-router'
import { ref, onMounted, inject, Ref, nextTick } from 'vue'
import { VueInstance } from 'src/Interfaces/VueInstance'
import { checkIsSeamlessPageTransition } from '../compositions/checkIsSeamlessPageTransition'

export const scrollTo = (element: Element, start: number, to: number, duration: number): Promise<void> => anime({ targets: element, scrollTop: [ start, to ], duration, easing: 'easeInOutQuad' }).finished

/**
 * Reverse transition animation when leaving a case study page
 */
export function registerPageLeaveHook() {

    onBeforeRouteLeave(async(to, from, next) => {

        const isSeamlessPageTransition = checkIsSeamlessPageTransition(to, from)

        if (isSeamlessPageTransition) {

            /**
             * Avoid taking too long to scroll up if user is already at the top of the page
             * this make the scroll dynamic.. the further you are the slower it takes to scroll
             */
            const total = document.documentElement.scrollHeight - window.innerHeight
            const percentage = window.scrollY * 100 / total

            await scrollTo(
                document.documentElement, window.scrollY, 0, 800 * percentage / 100
            )

        }

        next()

    })

}

export function getCommonMethods() {

    const header = ref<VueInstance | null>(null)
    const resetRevealStatus = ref(null)
    const presetExpandStatus = ref(null)
    const resumeVideo = ref(null)

    onMounted(() => {

        resetRevealStatus.value = header.value?.resetRevealStatus
        presetExpandStatus.value = header.value?.presetExpandStatus
        resumeVideo.value = header.value?.resumeVideo

    })

    return {
        header,
        resetRevealStatus,
        presetExpandStatus,
        resumeVideo
    }

}
